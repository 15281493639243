<template>
  <div>
    <v-card class="sc-chat-window" border>
      <v-row class="d-flex flex-row justify-end mt-2 px-3">
        <v-btn text @click="back" class="btn">
          <v-icon color="black"> mdi-chevron-left</v-icon> Voltar
        </v-btn>

        <v-spacer></v-spacer>
        <!-- <v-btn text @click="back" class="btn">
          <v-icon color="primary"> mdi-video</v-icon>
        </v-btn> -->
        <v-col cols="12">
          <p></p>
        </v-col>
      </v-row>
      <div
        ref="scrollList"
        class="sc-message-list"
        style="background: #f2f2f2"
        color="#0B1138"
      >
        <div class="chats">
          <template>
            <!-- {{users}}  -->
            <pre style="color: #000">
                    {{ API_URL }} 
                  </pre
            >
            <div v-for="(message, index) in messages" :key="index">
              <v-row>
                <v-col cols="12">
                  <!-- <v-row >
                    <v-col cols="1"> </v-col>
                    <v-col cols="11" >
                      <span
                        class=""
                        v-if="message.author.id !== currentUser.id"
                        style="font-size: 10pt;"
                      >
                        {{ message.author.name }}
                      </span>
                    </v-col>
                  </v-row> -->
                  <v-row>
                    <v-col cols="1">
                      <v-avatar
                        size="55"
                        v-if="message.author.id !== currentUser.id"
                      >
                        <v-img
                          :src="
                            message.author.photo != null
                              ? `${apiUrl}/images/user/${message.author.photo}`
                              : '/profile.png'
                          "
                        ></v-img>
                      </v-avatar>
                    </v-col>
                    <v-col cols="11">
                      <v-row class="time">
                        <span
                          class="message-time-in"
                          v-if="message.author.id !== currentUser.id"
                          style="margin-left: 0%; font-size: 12pt"
                          >{{ message.author.name }}</span
                        >
                        <span
                          class="message-time-in"
                          v-if="
                            message.author.id === currentUser.id &&
                            index != messages.length - 1
                          "
                        >
                          <span style="margin-left: 80%; font-size: 12pt">
                            Eu</span
                          >
                        </span>
                        <span
                          class="message-time-in"
                          v-if="
                            message.author.id === currentUser.id &&
                            index === messages.length - 1
                          "
                        >
                          <span style="margin-left: 80%; font-size: 12pt">
                            Eu</span
                          ></span
                        >
                      </v-row>
                      <p
                        class="message"
                        :class="{
                          'message-out': message.author.id === currentUser.id,
                          'message-in': message.author.id !== currentUser.id,
                        }"
                      >
                        {{ message.body }}
                      </p>
                      <v-row class="time">
                        <span
                          class="message-time-out"
                          v-if="message.author.id !== currentUser.id"
                          >{{ message.time }}</span
                        >
                        <span
                          class="message-time-in"
                          v-if="
                            message.author.id === currentUser.id &&
                            index != messages.length - 1
                          "
                          >{{ message.time }}
                        </span>
                        <span
                          class="message-time-in"
                          v-if="
                            message.author.id === currentUser.id &&
                            index === messages.length - 1
                          "
                          >{{ sendFlag ? message.time : "Enviando..." }}
                        </span>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </div>
          </template>
        </div>
      </div>
      <v-container style="background: #f2f2f2">
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="message"
              :prepend-inner-icon="icon"
              solo
              clear-icon="mdi-send"
              clearable
              label="Type your message here"
              type="text"
              @click:prepend="changeIcon"
              @click:append-outer="sendMessage"
              @click:clear="sendMessage"
              v-if="messages.length > 0"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>
<script>
// import { CHATS_QUERY } from "./../../graphql/Query";
import { SEND_MESSAGE_OUT, UPDATE_MESSAGE_OUT } from "./../../graphql/Mutation";
import { MESSAGE_SENT_SUBSCRIPTION } from "./../../graphql/subscription";
import { mapGetters } from "vuex";
import moment from "moment";
import { API_URL_AMER, API_URL } from "@/api";
import { CHATS_QUERY_AMER } from "../../graphql/amer/Query";
export default {
  props: ["users"],
  data: () => ({
    password: "Password",
    show: false,
    message: "",
    marker: true,
    iconIndex: 0,
    icons: [
      "mdi-emoticon",
      "mdi-emoticon-cool",
      "mdi-emoticon-dead",
      "mdi-emoticon-excited",
      "mdi-emoticon-happy",
      "mdi-emoticon-neutral",
      "mdi-emoticon-sad",
      "mdi-emoticon-tongue",
    ],
    messages: [],
    initMessage: true,
    checkSuportMessage: [],
    sendFlag: true,
    apiUrl: API_URL_AMER,
    apiUrl2: API_URL,
  }),
  apollo: {
    chats: {
      query: CHATS_QUERY_AMER,
      variables() {
        return { room: this.users.id };
      },
      fetchPolicy: "no-cache",
      result({ data }) {
        if (data.chats) {
          console.log("chats", data.chats);

          this.messages = data.chats.messages;
          if (this.messages.length > 1) {
            this.initMessage = false;
          }
          this.pushMessage();
        }
      },
    },
    $subscribe: {
      message: {
        query: MESSAGE_SENT_SUBSCRIPTION,
        // client: "apolloClientAmer",
        result({ data }) {
          //  console.log("addChat lllll", data.addChat.messages);

          if (data.addChat.messages.author.id === this.users.id) {
            console.log("addChat lllll", data.addChat.messages);
            this.messages.push(data.addChat.messages);
            this.pushMessage();
          }
        },
      },
    },
  },
  computed: {
    icon() {
      return this.icons[this.iconIndex];
    },
    ...mapGetters({
      currentUser: "auth/getCurrentUser",
    }),
  },
  methods: {
    toggleMarker() {
      this.marker = !this.marker;
    },
    async sendMessage() {
      const messageDisplay = this.$refs.scrollList;
      messageDisplay.scrollTop = messageDisplay.scrollHeight;
      this.messages.push({
        body: this.message,
        time: moment().format("LT"),
        date: moment().format("LL"),
        author: {
          id: this.currentUser.id,
          name: this.currentUser.name,
        },
        read: "1",
      });
      this.resetIcon();
      this.saveMessage();
      this.clearMessage();
    },
    clearMessage() {
      this.message = "";
    },
    resetIcon() {
      this.iconIndex = 0;
    },
    changeIcon() {
      this.iconIndex === this.icons.length - 1
        ? (this.iconIndex = 0)
        : this.iconIndex++;
    },
    back() {
      this.$emit("back");
    },

    async pushMessage() {
      await this.$apollo
        .mutate({
          mutation: UPDATE_MESSAGE_OUT,
          variables: {
            room: this.users.id,
            author: {
              id: this.currentUser.id,
              name: this.currentUser.name,
              photo: this.currentUser.photo,
              type: this.currentUser.type,
            },
          },
        })
        .then((res) => {
          if (res) {
            const messageDisplay = this.$refs.scrollList;
            messageDisplay.scrollTop = messageDisplay.scrollHeight;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async saveMessage() {
      this.sendFlag = false;
      await this.$apollo
        .mutate({
          mutation: SEND_MESSAGE_OUT,
          variables: {
            nameRoom: this.users.id,
            body: this.message,
            time: moment().format("LT"),
            date: moment().format("LL"),
            testID: null,
            to: {
              id: this.users.id,
              name: this.users.name,
              photo: this.users.photo,
              type: null,
              email: this.users.email,
            },
            flag: "Suport",
            author: {
              id: this.currentUser.id,
              name: this.currentUser.name,
              photo: this.currentUser.photo,
              type: this.currentUser.type,
            },
            from: "Amer",
          },
        })
        .then((res) => {
          if (res.data.sendMessageOutSide.name) {
            this.sendFlag = true;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  async mounted() {
    console.log(this.users);
    this.pushMessage();
  },
};
</script>
<style scoped src="@/assets/css/chat_css/chatMessageSupport.css"></style>
